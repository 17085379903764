import ButtonCellRenderer from '../OrderCellRenderer';


import Moment from 'moment';

const columnDefs = [
        {
            rowDrag:false,
            cellRenderer: 'agGroupCellRenderer',
            field:"id", 
     
            minWidth: 150,
           // headerCheckboxSelection: true, 
            //headerCheckboxSelectionFilteredOnly: true, 
            //checkboxSelection:true, 
            filter:'agSetColumnFilter',  
            sortable:true,

            cellStyle:(params) => {
                return {fontWeight: 700}
            
            },


            getQuickFilterText: function(params) {
                return params.value;
            }
        },
        {

            minWidth: 150,
            field:"status", 
            filter:true, 
            sortable:true,

            chartDataType: 'category',
            cellStyle: (params) => {
                switch (params.value) {
                    case 'unfulfilled':
                        return {backgroundColor: 'rgba(200,200,0,0.5)'};
                    case 'cancelled':
                        return {backgroundColor: 'rgba(255,0,0,0.4)'};
                    case 'fulfilled':
                    default:
                    return {backgroundColor: 'rgba(0,128,0, 0.4)'};//'green'};
                }
            
            }
        },
        {
            minWidth: 150,
            field:"first_name", 
            filter:'agSetColumnFilter', 
            sortable:true
        },
        {
            minWidth: 150,
            field:"last_name",
            filter:'agSetColumnFilter',  
            sortable:true
        },
        {
            minWidth: 150,
            field:"quantity", 
            filter:true, 
            sortable:true
        },
        {

            minWidth: 150,
            field:"amount", 
            filter:'agSetColumnFilter', 
            sortable:true, 
            chartDataType: 'series',
            valueFormatter : (params) => {
                return `$${params.value.toFixed(2)}`
            },
            cellStyle: (params) => {
                return {textAlign:'right'}
            }
        },

        {

            minWidth: 300,
            field:"email", 
            filter:'agSetColumnFilter', 
            sortable:true, 
        },
        {

            minWidth: 150,
            headerName:"Paid With",
            field:"payment_type", 
            filter:'agSetColumnFilter', 
            sortable:true, 
        },
        {

            minWidth: 150,
            headerName:"Payment Status",
            field:"payment_status", 
            filter:'agSetColumnFilter', 
            sortable:true, 
        },
        {
            
            minWidth: 300,            
            field:"date", 
            filter:'agSetColumnFilter', 
            sortable:true, 
            valueFormatter : (params) => {
                return `${new Moment(new Date(params.value)).format('DD/MM/YY h:mm a')}`;
            }
        },
       
        {   
            colId:'options',
            headerName: 'Options',
            minWidth: 400,
            editable: false,
            enableRowGroup: false,
            cellRenderer : ButtonCellRenderer,
            cellRendererParams: {
                
            } //(e) => {
                //return '<button onclick="console.log(this)">Test</button>'
            //}
            
        }
    ];



export default columnDefs;