
import './App.scss';
import Header from './layout/header';
import Body from './layout/body';
import {LicenseManager} from "@ag-grid-enterprise/core";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { getData } from './Ajax';

import React, {Component} from 'react';
import Login from './layout/components/Login';
import Loading from './layout/components/Loading';


LicenseManager.setLicenseKey("CompanyName=Excede,LicensedGroup=excede,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-013202,ExpiryDate=27_January_2022_[v2]_MTY0MzI0MTYwMDAwMA==4127596bcce43523d2d1d60072313dc4");








function setToken(token) {
  sessionStorage.setItem('token', token);
}


function getToken(token) {
  return sessionStorage.getItem('token');
}


export default class App extends Component {


  state = {
    loading: true,
    authenticated: false,
    token: null,
  }



  authenticated = (token) => {
    this.setState({authenticated: true, loading: false, token});
  }

  unAuthenticated = () => {
    this.setState({authenticated: false, loading: false, token: null});
  }


  unAuthenticate = () => {
    sessionStorage.removeItem('token');
    localStorage.removeItem('token');
    this.setState({authenticated: false, loading: false, token: null});
    
  }



  loggedInView = () => {
    return (
        <div className="App slow-load">
          
            <Router>
              <Switch>
                <Route exact path="/"><Header logout={this.unAuthenticate} page="/orders"/></Route>
                <Route exact path="/orders"><Header logout={this.unAuthenticate} page="/orders"/></Route>
                <Route exact path="/subscriptions"><Header logout={this.unAuthenticate} page="/subscriptions"/></Route>
                <Route exact path="/renewals"><Header logout={this.unAuthenticate} page="/renewals"/></Route>
                <Route exact path="/customers"><Header logout={this.unAuthenticate} page="/customers"/></Route>
                <Route exact path="/logout"><Header logout={this.unAuthenticate} page="/logout"/></Route>
                <Route><Header logout={this.unAuthenticate} page="404"/></Route>
              </Switch>
            </Router>
            <Body/>
        </div>
    )
  }



  authenticate = () =>  {
    console.log('Authenticating');
    let authenticated = false;
    if (!sessionStorage.getItem('token') && !localStorage.getItem('token')) {
      console.log('No token found.');
       this.unAuthenticated();
    } else {
      console.log('TOKEN FOUND, authenticating token.');
      getData(`/session/${sessionStorage.getItem('token') || localStorage.getItem('token')}`).then(response => {
          if (response.loggedIn === true) {
            console.log('LOGGED IN');
            this.authenticated(response.token);
          } else {
            this.unAuthenticated();
          }
      });
    }
    return authenticated;
  }


  componentDidMount() {
    this.authenticate();
  }



  render() {
    if (this.state.loading) {
        return <Loading/>;
    } else if (this.state.authenticated) {
      return this.loggedInView();
    } else {
      return <Login authenticated={this.authenticated}/>
    }
  }
  //return (getToken() ? loggedInView() : <Login setToken={token}/>)
}
