import React, {Component} from 'react';

import '../../App.scss';
import './_login.scss';
import { appendLoader, validInput, invalidInput} from '../../ElementBuilder';
import { getData, sendPost } from '../../Ajax';


export default class Login extends Component {
    state = {
        loggedIn : false
    }
    

    componentDidMount() {
        console.log('PROPS: ', this.props);
    }


    hash = async (message) => {
        // encode as UTF-8
        const msgBuffer = new TextEncoder('utf-8').encode(message);                    
        // hash the message
        const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer); 
        // convert ArrayBuffer to Array
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        // convert bytes to hex string                  
        const hashHex = hashArray.map(b => ('00' + b.toString(16)).slice(-2)).join('');
        return hashHex;
        
    }




    authenticate = async (e) => {
        e.preventDefault();
        let loginbtn = document.getElementById('login-btn');  
    
        let username_input = document.getElementById('username-input');
        let password_input = document.getElementById('password-input');
        let remember_input = document.getElementById('remember-input');
        let error_message = document.getElementById('error-message');
        let username = username_input.value;
        let password = await this.hash(password_input.value);
        if (!username || !password_input.value) {
            if (!password_input.value) {
                invalidInput(password_input.parentElement);
                error_message.innerHTML='Please enter a valid password.';
            } else {
                validInput(password_input.parentElement);
            }

            if (!username) {
                invalidInput(username_input.parentElement);
                error_message.innerHTML='Please enter a valid username or email.';
            } else {
                validInput(username_input.parentElement);
            }
            return;
        }

        loginbtn.innerHTML = '';
        appendLoader(loginbtn); 

        loginbtn.disabled = true;
        username_input.disabled = true;
        password_input.disabled = true;
        remember_input.disabled = true;


       await sendPost('/login', {username, password}).then(response => {
            if (response.success) {
                
                error_message.style.color = "black"
                error_message.innerHTML='Login success!';
                
                validInput(username_input.parentElement);
                validInput(password_input.parentElement);

                sessionStorage.setItem('token', response.token);
                this.props.authenticated(response.token);
                
                if (remember_input.checked)
                    localStorage.setItem('token', response.token);
            } else {
                loginbtn.innerHTML = 'Login';
                loginbtn.disabled = false;
                

                invalidInput(username_input.parentElement);
                invalidInput(password_input.parentElement);
                error_message.innerHTML='The username or password you entered was incorrect.';
                username_input.disabled = false;
                password_input.disabled = false;
                remember_input.disabled = false;
            }
       });
    }
    render () {
        return(
                <div className="login-container slow-load">
                    <img width={400} height={200} src="https://scatbags.com/assets/logo/scatbagslogo.svg"/>



                    <div className="login-modal-wrapper">
                        <div className="login-modal">
                            <form onSubmit={this.authenticate}>
                                <h2>Login to Scatbags</h2>
                                <div className="input-group">
                                    <label>Username</label>
                                    <input name="username" id="username-input" autoComplete="username" type="text" placeholder="Username or Email"/>
                                </div>
                                <div className="input-group">
                                    <label>Password</label>
                                    <input name="password" id="password-input" autoComplete="current-password" type="password" placeholder="•••••••••••••••••••••"/>
                                </div>

                                <div className="input-group">
                                    <label>Remember</label>
                                    <div className="checkbox-wrapper">
                                        <input name="remember" id="remember-input" type="checkbox"/>
                                    </div>
                                </div>
                                <button id="login-btn" className="standard-btn" type="submit">Login</button>
                                <div style={{display: 'flex', justifyContent:'center', width: '100%', height:'60px'}}>
                                    <p id="error-message" className="error-message"></p>
                                </div>
                                
                            </form>
                            
                        </div>
                    </div>
                    
                    
                </div>
        
            
        )
    }
}