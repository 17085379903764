import React, {Component} from 'react';




export default class Loading extends Component {


    render() {
        return (
                
                <div style={{position: 'fixed', display: 'flex', justifyContent:'center', alignItems:'center', width:'100%', height:'100%'}} className="slow-load">
                    <div className="loader-container">
                        <div className="loader med">
                        </div>
                    </div>
                </div>
            
            



        );
    }
}