import React from 'react';
import {sendPost, getData} from '../../../../Ajax';
import {appendLoader, appendCheckmark, appendButtonCheckmark} from '../../../../ElementBuilder';
import { textChangeRangeIsUnchanged } from 'typescript';


// cell renderer class
export default class ButtonCellRenderer {}



ButtonCellRenderer.prototype.draw = function(params) {
    console.log(params);
 } 



 ButtonCellRenderer.prototype.refresh  = function (params) {
     console.log("REFRESH CALLED")
    return true;
  };



// init method gets the details of the cell to be rendere
ButtonCellRenderer.prototype.init = function(params) {
    this.eGui = document.createElement('span');



    /**
     * Creates the order fulfillment button.
     */
    this.chargeButton = document.createElement('button');
    this.chargeButton.classList.add('renew-btn');
    this.chargeButton.innerHTML = 'Renew';
    this.chargeButton.id = 'renew-btn-' + params.node.data.unique_id;

    if (params.node.data.last_payment_failed) {
        this.chargeButton.innerHTML = 'Retry';
        this.chargeButton.classList.add('error');
    }
   

    /**
     * Fulfillment event listener
     */
    this.chargeButton.addEventListener('click', (e) => {
        let api = params.api;
        let node = params.node;
        let data = node.data;
        
        if (this.chargeButton.classList.contains('renewed')) {
            return;
        }

        //console.log('api: ', params.api.);

        this.chargeButton.disabled = true;
        this.chargeButton.innerHTML = '';
        appendLoader(this.chargeButton, 'tiny');
        sendPost('/subscriptions/renew', {id:data.unique_id, token: data.method_token, amount: data.total}).then(result => {
            console.log(result);
            if (result.success) {
              
                this.chargeButton.innerHTML = '';
                appendButtonCheckmark(this.chargeButton, 'tiny');
                this.chargeButton.classList.add('renewed');
                if (this.chargeButton.classList.contains('error')) 
                    this.chargeButton.classList.remove('error');
                this.chargeButton.disabled = false;
        
            } else {
                this.chargeButton.innerHTML = 'Retry';
                if (!this.chargeButton.classList.contains('error'))
                    this.chargeButton.classList.add('error');
                this.chargeButton.disabled = false;
            }
        });

        /*
        data.status = data.status === 'cancelled' ? 'onhold' : (data.status === 'active' ? 'onhold' : 'active');
        
        if (data.status !== 'cancelled') {
            this.cancelButton.innerHTML = 'Cancel';
            this.cancelButton.disabled = false;
        }

        this.chargeButton.innerHTML = data.status === 'cancelled' ? 'Reinstate' : (data.status === 'active' ? 'Pause' : 'Resume');
        params.api.applyTransaction({update: [node.data]});*/
        //sendPost('/subscriptions/update', {id:data.unique_id, target:'status', update:data.status}).then(result => console.log(result));
    });









    this.eGui.appendChild(this.chargeButton);

};

ButtonCellRenderer.prototype.getGui = function() {
    return this.eGui;
};
