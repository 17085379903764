
export function invalidInput(input) {

    if (input.classList.contains('valid')) {
        input.classList.remove('valid');
    }
    if (!input.classList.contains('invalid')) {
        input.classList.add('invalid');
    }
}


export function validInput(input) {
    if (input.classList.contains('invalid')) {
        input.classList.remove('invalid');
    }

    if (!input.classList.contains('valid')) {
        input.classList.add('valid');
    }
}


export function hide(element) {
    if (!element.classList.contains('hide')) {
        element.classList.add('hide');
    }
}



export function hideMultiple(...elements) {
    elements.forEach(element => {
        hide(element);
    });
}


export function showMultiple(...elements) {
    elements.forEach(element => {
        show(element);
    });
}


export function show(element) {
    if (element.classList.contains('hide')) {
        element.classList.remove('hide');
    }
}


/**
 * Creates a LOADER DOM element for you to append to a container.
 * @param {*} attachment 
 */
export function appendLoader(attachment, size) {
    
    let loader = document.createElement('div');

    if (!size) {
        loader.classList.add('loader', 'small');
    } else {
        loader.classList.add('loader', size);
    }
    let loaderContainer = document.createElement('div');
    loaderContainer.classList.add('loader-container');

    loaderContainer.appendChild(loader);

    attachment.appendChild(loaderContainer);
}




export function appendButtonCheckmark(attachment, size) {
    
    let checkmark = document.createElement('span');

    if (!size) {
        checkmark.classList.add('checkmark', 'small');
    } else {
        checkmark.classList.add('checkmark', size);
    }
   
    checkmark.innerHTML = '&#10003;';
    let loaderContainer = document.createElement('div');
    loaderContainer.classList.add('loader-container');
    
    loaderContainer.appendChild(checkmark);

    attachment.appendChild(loaderContainer);
    console.log('ATTACHED');
}


/**
 * Creates a checkmark element for you to append to a DOM container.
 */
export function appendCheckmark(size) {
    let checkmark = document.createElement('span');
    checkmark.innerHTML = '&#10003;';

    checkmark.classList.add('checkmark')

   

    return checkmark;

}



/**
 * 
 * Creates an DOM paragraph element for you to append to a container containing a message with the styling of an error.
 * @param {*} message 
 */
export function appendError(message) {
    let element = document.createElement('p');
    element.classList.add('error-message');
    element.innerHTML = message;

    return element;
}



export function appendInputGroup(placeholder, type, labeltext, required) {

    let group = document.createElement('div');
    group.classList.add('input-group');

    let input = document.createElement('input');
    input.type = type;
    input.placeholder = placeholder;

    let label = document.createElement('label');
    label.innerHTML = labeltext;
    if (required) 
        label.classList.add('required');

    group.appendChild(label);
    group.appendChild(input);
 
    return [group, input, label];
}

/**
 * 
 * The header, body, and footer should be DOM elements with their own nested children.
 * 
 * e.g. 
 * header = document.createElement('div');
 * body = document.createElement('div');
 * footer = document.createElement('div');
 * 
 * @param {*} header The content inside the header.
 * @param {*} body The content inside the body of the modal.
 * @param {*} footer The content inside the footer.
 * @param {*} size The size of the modal (big, small etc..)
 * @param {*} closeable Should there be an x for close button?
 */
export default function buildModal(header, body, footer, size, closeable) {
        console.log('BUILDING MODAL NODE...');

        let modal = document.createElement('div');
        modal.classList.add('modal');
        
        let modalContent = document.createElement('div');
        let modalHeader = document.createElement('div');
        let modalBody = document.createElement('div');
        let modalFooter = document.createElement('div');
        
        modalContent.classList.add('modal-content', size);
        modalHeader.classList.add('modal-header');
        modalBody.classList.add('modal-body');
        modalFooter.classList.add('modal-footer');
        

        if (closeable) {
            let closeButton = document.createElement('span');
            closeButton.classList.add('close-modal');
            closeButton.innerHTML = `&times;`;
            closeButton.addEventListener('click', (e) => {
                modal.remove();
            });
    
             modalHeader.appendChild(closeButton);
       
        }
        

        

        body.style.padding = '5px';
        header.style.padding = '5px';
        footer.style.padding = '5px';

       
       
       
        modalHeader.appendChild(header);
        modalBody.appendChild(body);
        modalFooter.appendChild(footer);
        modalContent.appendChild(modalHeader);
        modalContent.appendChild(modalBody);
        modalContent.appendChild(modalFooter);
        modal.appendChild(modalContent);

        document.getElementById('root').appendChild(modal);
 
     
        return modal;

}