import buildModal, {appendLoader, appendCheckmark, appendError, validInput, invalidInput, hide, show, showMultiple, hideMultiple} from '../ElementBuilder';
import {sendPost, getData} from '../Ajax';
import { invalid } from 'moment';

export function getTotal(refund_data) {
    let sum = 0;
    refund_data.map(element => {
        sum += parseFloat(element.amount);
        return element;
    });
    return sum;
}





export function addNumberValidation(input, min_value, max_value) {
    input.addEventListener('keydown', (e) => {
        console.log(e.target.value)
        if ((parseFloat(e.target.value) > max_value) || (parseFloat(e.target.value) <= min_value)) {
           invalidInput(input);
        } else {
            validInput(input);
        }
    });

    input.addEventListener('keyup', (e) => {
        if ((parseFloat(e.target.value) > max_value) || (parseFloat(e.target.value) < min_value)) {
            invalidInput(input);
        } else {
             validInput(input);
            
        }
    });
}



export function getRemaining(refund_data, total) {
    let remaining = total;
    refund_data.map(element => {
        remaining-= parseFloat(element.amount);
        return element;
    });
    return remaining.toFixed(2);
}





/**
 * Builds the modal that holds the refund form.
 * DATA requires JSON type in this format example: {first_name: "Morgan", last_name:"Drew", id: 444, amount: '10.44'}
 * @param {*} data 
 */
export function buildRefundModal(data, node, api) {
    /**
     * Initialize ELEMENTS
     */
    

    let loading = false;
    

    let header = document.createElement('div');
    let body = document.createElement('div');
    let footer = document.createElement('div');


    console.log(data);
    let refund_data = data.refund_data;


    let total_refunded = refund_data ? getTotal(refund_data).toFixed(2) : '0.00';
    let total_remaining = refund_data ? getRemaining(refund_data, data.amount) : data.amount.toFixed(2);

    /**
     * HEADER CONTENT
     */
    let title = document.createElement(`h3`);
    title.innerText = `Refund Order - ${data.id} - ${data.first_name} ${data.last_name}`;
    header.appendChild(title);

    let content = document.createElement('div');
    content.innerHTML = `
        <p>Order Total: $${data.amount.toFixed(2)}</p>
        <p>Refunded: $${total_refunded}</p>
        <p>Remaining: $${total_remaining}</p>
    `

    let form = document.createElement('div');
    form.classList.add('form-group');

    let amountInputGroup = document.createElement('div');
    amountInputGroup.classList.add('input-group');
   



    
    let amountLabel = document.createElement('label');
    amountLabel.classList.add('required');
    amountLabel.innerText = 'Refund Amount';


    let amountInput = document.createElement('input');
    amountInput.placeholder = "10.50";
    
    
    addNumberValidation(amountInputGroup, 0, total_remaining);

    amountInputGroup.appendChild(amountLabel);
    amountInputGroup.appendChild(amountInput);


    let reasonInputGroup = document.createElement('div');
    reasonInputGroup.classList.add('input-group');
    

    let reasonLabel = document.createElement('label');
    reasonLabel.classList.add('required');
    reasonLabel.innerText = 'Reason';

    let reasonInput = document.createElement('input');
    reasonInput.placeholder = "Reason for applying refund";
    //reasonInput.rows = "3";
   // reasonInput.cols="50";
    
    reasonInputGroup.appendChild(reasonLabel);
    reasonInputGroup.appendChild(reasonInput);

    form.appendChild(amountInputGroup);
    form.appendChild(reasonInputGroup);
    body.appendChild(content);
    body.appendChild(form);



 

    
    
    
    /**
     * FOOTER CONTENT
     */
    footer.classList.add('btn-row', 'full', 'right');

    let closeModal = document.createElement('button');
    closeModal.classList.add('standard-btn');
    closeModal.innerHTML = 'Cancel';



    let refundButton = document.createElement('button');
    refundButton.classList.add('standard-btn');
    let refundText = document.createElement('div');
    refundText.innerText = 'Refund';
    refundButton.appendChild(refundText);




   

    footer.appendChild(closeModal);
    footer.appendChild(refundButton);
    
    let master = buildModal(header, body, footer, 'small', false);



    closeModal.addEventListener('click', (e) => {
        master.remove();
    });



    window.onclick = (e) => {
        if (!loading && e.target === master) {
            console.log('Removing window');
            master.remove();
        }
    }

    document.onkeydown = (e) => {
        if (!loading && e.key.toLowerCase() === 'escape') {
            master.remove();
        }
    }
     


     /**
     * REFUND BUTTON EVENT HANDLER 
      */   
     refundButton.addEventListener('click', (e) => {
        if (!loading) {
            
            /**
             * Removes any existing error messages to restart the log.
             */
            let errorMessages = body.querySelector('.error-message');
            if (errorMessages) 
                errorMessages.remove(); 

        
            if (parseFloat(amountInput.value) > total_remaining) {
                body.appendChild(appendError('You can\'t refund more than the remaining refundable balance.'));
                invalidInput(amountInputGroup);
                return;
            }


            if (parseFloat(amountInput.value) <= 0 || !amountInput.value) {
                body.appendChild(appendError('You must specify an amount to refund.'));
                invalidInput(amountInputGroup);
                return;
            }

            if (!reasonInput.value) {
                body.appendChild(appendError('You must give a reason for refunding.'));
                invalidInput(reasonInputGroup);
                return;
            }


            loading = true;
            
           


           
            amountInput.disabled = true;
            reasonInput.disabled = true;
            hideMultiple(closeModal, refundText);
            appendLoader(refundButton);


            let amount = parseFloat(amountInput.value);
            let new_remaining = parseFloat(total_remaining - amount);
            let reason = reasonInput.value;

            sendPost('/orders/refund', {amount, id: data.id, reason, transaction_id : data.transaction_id, full: amount >= total_remaining, new_remaining}).then(async result => {
                console.log(result);


                if (result.success) {
                    
                    body.innerHTML = '';
                    let modalContainer = document.createElement('div');
                    modalContainer.classList.add('block');
                    modalContainer.style.textAlign = "center";

                    let checkmark = appendCheckmark();
                    
                    let title = document.createElement('h3');
                 
                    title.innerHTML = `Order ${data.id} Successfully refunded $${result.amount}`;
                    
                    modalContainer.appendChild(checkmark);  
                    modalContainer.appendChild(title);

                    body.appendChild(modalContainer);

                    footer.innerHTML = '';
                    let newClose = document.createElement('button');
                    newClose.classList.add('standard-btn');
                    newClose.innerHTML = 'Done';


                    newClose.addEventListener('click', (e) => {
                        master.remove();
                    });
                    footer.appendChild(newClose);


                    data.payment_status = amount >= total_remaining ? 'refunded' : 'partially-refunded';
                    
                    // Gets the new Refund Data.
                    await getData(`/orders/get/${data.id}`).then(response => {
                        data.refund_data = JSON.parse(response[0].refund_data);
                        console.log('REFUND DATA:',data.refund_data);
                    });

                   // await node.setData(data);
                    await api.applyTransaction({update: [data]});
                
                } else { 

                    body.appendChild(appendError(result.message));
                    invalidInput(amountInputGroup)
                   
                   
                    refundButton.lastChild.remove();
                    showMultiple(closeModal, refundText);
                    amountInput.disabled = false;
                    reasonInput.disabled = false;
                    loading = false;

                    
                    

                   
                }
               
                
                console.log(result.message);
            });
        }
    });


}