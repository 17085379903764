import ButtonCellRenderer from '../RenewCellRenderer';


import Moment from 'moment';

const renewDefs = [
        {
            rowDrag:false,
            cellRenderer: 'agGroupCellRenderer',
            field:"unique_id",
            headerName: 'Key',
            
            minWidth: 150,
           // headerCheckboxSelection: true, 
            //headerCheckboxSelectionFilteredOnly: true, 
            //checkboxSelection:true, 
            filter:'agSetColumnFilter',  
            sortable:true,

            cellStyle:(params) => {
                return {fontWeight: 700}
            
            },


            getQuickFilterText: function(params) {
                return params.value;
            }
        },
        {


            minWidth: 150,
            field:"next_payment",
            headerName:'Renewal Date',

            filter:true, 
            sortable:true,

            valueFormatter: (params) => {
                let date = Moment(new Date(params.value)).format('DD/MM/YYYY');
                return date; 
            }               
            
           
        },
        {

            minWidth: 150,
            field:"name", 
            filter:'agSetColumnFilter', 
            sortable:true
        
        },
        {

            minWidth: 150,
            field:"quantity", 
            filter:true, 
            sortable:true,

            cellStyle:(params) => {
                return {fontWeight: 700}
            
            },
        },

        {


            minWidth: 150,
            field:'total',
            headerName:'Amount',


        
            cellStyle:(params) => {
                return {textAlign:'right'}
            },

          valueFormatter: (params) => {
                return `$${params.value.toFixed(2)}`;
            }
            
        },

        {


            minWidth: 300,
            field:"email", 
            filter:'agSetColumnFilter', 
            sortable:true, 
        },
        {


            minWidth: 150,
            field:"mobile", 
            filter:'agSetColumnFilter', 
            sortable:true, 
        },

        {   
            colId:'options',
            headerName: 'Options',
            minWidth: 400,
            editable: false,
            enableRowGroup: false,
            cellRenderer : ButtonCellRenderer,
            cellRendererParams: {
                
            },
        }
       /* {
            field:"bundle_price", 
            filter:'agSetColumnFilter', 
            sortable:true, 
            chartDataType: 'series',
            valueFormatter : (params) => {
                return `$${params.value.toFixed(2)}`
            }
        },

        
        {
            field:"date_created", 
            filter:'agSetColumnFilter', 
            sortable:true, 
            valueFormatter : (params) => {
                return `${new Moment(new Date(params.value)).format('DD/MM/YY h:mm a')}`;
            }
        },
       
        {   
            colId:'options',
            headerName: 'Options',
            minWidth: 400,
            editable: false,
            enableRowGroup: false,
            cellRenderer : ButtonCellRenderer,
            cellRendererParams: {
                
            } *////(e) => {
                //return '<button onclick="console.log(this)">Test</button>'
            //}
            
       // }
        ];



export default renewDefs;