import {Component} from 'react';

import {api_url} from './config';



const ajaxProps = data => {

    return {
    method:'POST',
    headers:{ 
        'Content-Type' : 'application/json'
    },
    body: JSON.stringify(data)
    };

};

/**
 * Start URL with '/'to send to api url from your main server url configured in the configs file.
 * 
 * URL should be e.g. '/endpoint'
 * 
 * data should be in JSON format ie 
 *  {title:'hello', username:'world'}
 * 
 * example of function structure.
 * 
 * sendPost('/target-endpoint', {test:1, anotherTest: 'HELLO'});
 * 
 * @param {*} url 
 * @param {*} data 
 */
export function sendPost(url, data) {
    return new Promise(async (resolve, reject) => {
        await fetch(`${api_url + url}`, ajaxProps(data))
            .then(response => response.json())
            .then(response => {
                resolve(response);
            });
    });
}

export async function getData(endpoint) {
    let data = [];
    await fetch(`${api_url + endpoint}`).then(res => res.json()).then(res => data = res);
    return data;
}

