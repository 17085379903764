import React, {Component} from 'react';
import Moment from 'moment';


const getDetailTemplate = (params) => {
    let data = params.data;
    return (



        `<div class="detail-container">
        <div class="detail-column">
            <p class="detail-column-header">
            SHIPPING ADDRESS
            </p>
            <p class="detail-column-item">${data.address_line_1} ${data.address_line_2}</p>
            <p class="detail-column-item">${data.city} ${data.state} ${data.postal_code}</p>
            <p class="detail-column-item">${data.country}</p>
        </div>
        <div class="detail-column">
            <p class="detail-column-header">
            CONTACT INFORMATION
            </p>
            <p class="detail-column-item">${data.first_name} ${data.last_name}</p>
            <p class="detail-column-item">${data.mobile}</p>
            <p class="detail-column-item">${data.email}</p>
        </div>
       

    </div>`
    /*
    `
        <div class="block">
        <div class="row left">
                
        </div>
        <div class="container toolbar">
            <div class="row full left" style={{paddingLeft: 5}}>
                <h2>${data.first_name} ${data.last_name}</h2>
                
            </div>
        
        </div>
        
        
        <div class="container toolbar">
                <div class="btn-row half top left">
                    <table>
                        <tbody>
                            <tr class="detail-row">
                                <td class="detail-li">
                                    <p>Email</p>
                                </td>
                                <td class="detail-li">
                                    <p>${data.email}</p>
                                </td>
                            </tr>
                            <tr class="detail-row">
                                <td class="detail-li">
                                    <p>Phone</p>
                                </td>
                                <td class="detail-li">
                                    <p>${data.mobile}</p>    
                                </td>
                            </tr>
                        
                        </tbody>
                    </table>
                </div>
                <div class="btn-row half top right">
                    <table>
                        <tbody>
                      
                            <tr class="detail-row">
                                <td class="detail-li">
                                    <p>Address</p>
                                </td>
                                <td class="detail-li">
                                    <p>${data.address_line_1} ${data.address_line_2}</p>
                                </td>
                            </tr>
                            <tr class="detail-row">
                                <td class="detail-li">
                                    <p>City</p>
                                </td>
                                <td class="detail-li">
                                    <p>${data.city}</p>
                                </td>
                            </tr>
                            <tr class="detail-row">
                                <td class="detail-li">
                                    <p>State</p>
                                </td>
                                <td class="detail-li">
                                    <p>${data.state}</p>
                                </td>
                            </tr>
                            <tr class="detail-row">
                                <td class="detail-li">
                                    <p>Postcode</p>
                                </td>
                                <td class="detail-li">
                                    <p>${data.postal_code}</p>
                                </td>
                            </tr>
                            <tr class="detail-row">
                                <td class="detail-li">
                                    <p>Country</p>
                                </td>
                                <td class="detail-li">
                                    <p>${data.country}</p>
                                </td>
                            </tr>
                            <tr class="detail-row">
                                <td class="detail-li">
                                    <p>Date</p>
                                </td>
                                <td class="detail-li">
                                    <p>${new Moment(new Date(data.date_created)).format('DD/MM/YY H:MM a')}</p>
                                </td>
                            </tr>
                            

                        </tbody>
                    </table>
                </div>
                
        </div>
    </div>`*/

    );
};



export default getDetailTemplate;