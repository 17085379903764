import React from 'react';
import {sendPost, getData} from '../../../../Ajax';
import {buildRefundModal} from '../../../../utilities/RefundUtils';

// cell renderer class
export default class ButtonCellRenderer {}



ButtonCellRenderer.prototype.draw = function(params) {
    console.log(params);
 } 



 ButtonCellRenderer.prototype.refresh  = function (params) {
     console.log("REFRESH CALLED")
    return true;
  };



// init method gets the details of the cell to be rendere
ButtonCellRenderer.prototype.init = function(params) {
    this.eGui = document.createElement('span');



    /**
     * Creates the order fulfillment button.
     */
    this.fulfillButton = document.createElement('button');
    this.fulfillButton.innerHTML = params.node.data.status === 'cancelled' ? 'Reinstate' : (params.node.data.status === 'unfulfilled' ? 'Fulfill' : 'Unfulfill');



    /**
     * Fulfillment event listener
     */
    this.fulfillButton.addEventListener('click', () => {
        let api = params.api;
        let node = params.node;
        let data = node.data;
        
        data.status = data.status === 'cancelled' ? 'unfulfilled' : (data.status === 'fulfilled' ? 'unfulfilled' : 'fulfilled');
        
        if (data.status !== 'cancelled') {
            this.cancelButton.innerHTML = 'Cancel';
            this.cancelButton.disabled = false;
        }

        this.fulfillButton.innerHTML = data.status === 'cancelled' ? 'Reinstate' : (data.status === 'fulfilled' ? 'Unfulfill' : 'Fulfill');
        params.api.applyTransaction({update: [node.data]});
        sendPost('/orders/update', {id:data.id, target:'status', update:data.status}).then(result => console.log(result));
    });





    /**
     * Creates the cancel button.
     */
    this.cancelButton = document.createElement('button');
    this.cancelButton.innerHTML = params.node.data.status ==='cancelled' ? 'Cancelled' : 'Cancel';
    this.cancelButton.disabled = params.node.data.status === 'cancelled' ? true : false;
    
    
    
    /**
     * Cancellation listener
     */
    this.cancelButton.addEventListener('click', () => {
        let api = params.api;
        let node = params.node;
        let data = node.data;
        data.status = 'cancelled';
        this.fulfillButton.innerHTML = 'Reinstate';
        node.setData(data);
        this.cancelButton.disabled = true;
        this.cancelButton.innerHTML = data.status === 'cancelled' ? 'Cancelled' : 'Cancel';
        
        params.api.applyTransaction({update: [node.data]});
        sendPost('/orders/update', {id:data.id, target:'status', update:data.status}).then(result => console.log(result));
    });




    /**
     * Creates the refund button.
     */
    this.refundButton = document.createElement('button');
    this.refundButton.innerHTML = `${!params.node.data.refund_data ? 'Refund' : 'Partial Refund'}`;
    

    if (params.node.data.payment_status === 'refunded') {
        this.refundButton.disabled = true;
        this.refundButton.innerHTML = 'Refunded';
    }


    /**
     * Refund event listener
     */
    this.refundButton.addEventListener('click', () => {

        let header = document.createElement('div');
        let body = document.createElement('div');
        let footer = document.createElement('div');
        
        
       
        
        console.log('woop');
        
        buildRefundModal(params.node.data, params.node, params.api);


    });


    this.eGui.appendChild(this.fulfillButton);
    this.eGui.appendChild(this.cancelButton);
    this.eGui.appendChild(this.refundButton);

};

ButtonCellRenderer.prototype.getGui = function() {
    return this.eGui;
};
