import React, {Component} from 'react';

import OrdersGrid from './components/ag-grid/OrdersGrid';
import SubsGrid from './components/ag-grid/SubsGrid';
import RenewalGrid from './components/ag-grid/RenewalGrid';
import CustomerGrid from './components/ag-grid/CustomerGrid';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";

import '../App.scss';


export default class Header extends Component {

    render() {
        return (
            <div className="body">
                <Router>

                    <Switch>

                    
                        <Route exact path="/orders">
                            <OrdersGrid/>
                        </Route>
                        <Route exact path="/">
                            <OrdersGrid/>
                        </Route>
                        <Route exact path="/subscriptions">
                            <SubsGrid/>
                        </Route>
                        <Route exact path="/renewals">
                            <RenewalGrid/>
                        </Route>

                        <Route exact path="/customers">
                            <CustomerGrid/>
                        </Route>

                        <Route>
                            <div style={{display:'block'}}>

                     
                            <h1>404</h1>
                            <h2>
                               Please select a page from the menu.
                            </h2>

                            </div>

                        </Route>

                    </Switch>
                    
                </Router>
                
            </div>
        )
    }
}