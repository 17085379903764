import React, {Component} from 'react';
import {AgGridColumn} from 'ag-grid-react';


import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import columnDefs from './defaults/definitions/customerheaders';
import { AgGridReact } from '@ag-grid-community/react';

import {AllModules} from '@ag-grid-enterprise/all-modules';

import {getData} from '../../../Ajax';


import getDetailTemplate from './detail/CustomerDetail';

export default class CustomerGrid extends Component {
    

    // STATE AND CELL OPTIONS
    state = {
        loading : false,
        reportMode : false,
        colId: 'id' || 'unique_id',
        sortingOrder: ['desc', 'asc', null],
        columnDefs: columnDefs,
        defaultColDef: {
          resizable: true, 
          flex: 1, 
          autoHeight: true,
          enableRowGroup: true,
          enablePivot: true,
          enableValue: true,
        },

        rowGroupPanelShow: 'never',//'always',//
        
        rowData: null,
        lastExpanded: '1',
        gridApi: null,
        gridColumnApi: null,
        modules: AllModules,


        detailCellRendererParams: {
          
          refreshStrategy: 'everything',

          template: function(params) {
            return getDetailTemplate(params);
          },

          detailGridOptions: {
            immutableData: true,
    
          },

          getDetailRowData: function(params) {
            console.log('GETTING ROW DATA');
            params.successCallback(params.data);
        },
      
        },
        


        gridOptions: {
           
            pagination: false,
            onRowClicked : (event) => {
                console.log('ROW CLICKED', event);
            },
            onColumnResized: function(event) { console.log('A column was resized'); },
            onGridReady: function(event) { console.log('The grid is now ready'); },
            // CALLBACKS
            //isScrollLag: function() { return false; },

            headerHeight: 50,
            getContextMenuItems: (params) => {
              console.log(params);
             if (params.column === null) {
               return ['copy'];
             } else {
               return [
                 'autoSizeAll',
                 'copy',
                 'copyWithHeaders',
                 'paste',
                 'resetColumns',
                 'export',
                 'csvExport',
                 'excelExport',
                 'excelXmlExport',
                 'chartRange',
               ]
             }
           },
        },

        groupDefaultExpanded: 1,
        
        detailRowHeight: 200,
        


        customChartThemes: {
            myCustomTheme: {
              palette: {
                fills: ['white', 'white', 'peru'],
                strokes: ['black', '#white'],
              },
              overrides: {
                common: {
                  padding: {
                    top: 20,
                    right: 30,
                    bottom: 10,
                    left: 2,
                  },
                  background: { fill: '#15012c' },
                  title: {
                    enabled: true,
                    fontStyle: 'italic',
                    fontWeight: '600',
                    fontSize: 18,
                    fontFamily: 'arial, sans-serif',
                    color: 'white',
                  },
                  legend: {
                    enabled: true,
                    position: 'left',
                    padding: 20,
                    item: {
                      label: {
                        fontStyle: 'italic',
                        fontWeight: 'bold',
                        fontSize: 18,
                        fontFamily: 'arial, sans-serif',
                        color: 'white',
                      },
                      marker: {
                        type: 'diamond',
                        size: 10,
                        padding: 10,
                        strokeWidth: 2,
                      },
                      paddingX: 120,
                      paddingY: 20,
                    },
                  },
                },
              },
            },
          },
          chartThemes: ['myCustomTheme', 'ag-pastel', 'ag-vivid'],

        chartThemeOverrides: {
            common: {
              title: {
                enabled: true,
                text: 'Medals by Age',
              },
              legend: { position: 'bottom' },
            },

            background: { fill: '#15012c' },

            column: { axes: { category: { label: { rotation: 0 } } } },
          },   
    }

    onFirstDataRendered = (params) => {
      //this.gridColumnApi.autoSizeColumn('options', true)
    //  params.api.,forEachNode(function (node) {
    //      node.setExpanded(node.id === lastExpanded);
      //});

        
        /*var createRangeChartParams = {
            cellRange: {
              rowStartIndex: 0,
              rowEndIndex: 79,
              columns: ['amount', 'status'],
            },
            chartType: 'groupedColumn',
            chartContainer: document.querySelector('#myChart'),
            aggFunc: 'sum',
          };
          params.api.createRangeChart(createRangeChartParams);*/
       
    };




    onRowDragEnter = (e) => {
        console.log('onRowDragEnter', e);
      };
    
      onRowDragEnd = (e) => {
        console.log('onRowDragEnd', e);
      };
    
      onRowDragMove = (e) => {
        console.log('onRowDragMove', e);
      };
      onColumnDragEnter = (e) => {
        console.log(e);
      };
    
      onRowDragLeave = (e) => {
        console.log('onRowDragLeave', e);
      };

    // When the grid is ready.
    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.setState({gridApi: this.gridApi, gridColumnApi: this.gridColumnApi});
        getData('/customers/get').then(async res => { 
            await res.forEach(data => {

              try {
              data.all_orders = JSON.parse(data.all_orders);

              if (data.all_orders != null && data.all_orders.length > 1) {
                console.log(data.all_orders);
              }
              
              } catch (err) {
                console.log(data.all_orders);
                if (err)
                  throw err;
              }
            });  
          
        
          await this.setState({rowData:res})
        
        
        });
        
    }



    // Test button click.
    onButtonClick = e => {
        const selectedNodes = this.gridApi.getSelectedNodes()
        const selectedData = selectedNodes.map( node => node.data )
        const selectedDataStringPresentation = selectedData.map( node => node.id).join(', ')
        alert(`Selected nodes: ${selectedDataStringPresentation}`)
    }


    refreshGrid = e => {
        console.log('View refreshed.')
        this.state.gridOptions.api.refreshCells();
    }


    onFilterTextBoxChanged = (e) => {
      console.log(e);
      this.gridApi.setQuickFilter(e.target.value);

    }

    // Render.
    render() {
        return(
            <div className="ag-theme-alpine" style={{ height: 900, width: '100%'}}>
           
                <div className="container toolbar" style={{paddingLeft: 5}}>
                    <div className="btn-row full left">
                        
                      <div className="input-group">
                        <label>Search for:</label>
                        <input type="text" placeholder="Type here to filter..." onInput={this.onFilterTextBoxChanged}/>
                      </div>

                    </div>
                </div>
                    
                <AgGridReact
                    modules={this.state.modules}
                    enableMultiRowDragging={true}
                    rowDragManaged={false}
                    rowHeight="30"
                    gridOptions={this.state.gridOptions}
                    rowGroupPanelShow={this.state.rowGroupPanelShow}
                    masterDetail={true}
                    enableCellChangeFlash={true}
                    detailCellRendererParams={this.state.detailCellRendererParams}
                    detailRowHeight={this.state.detailRowHeight}
                    rowData={this.state.rowData} 
                    onGridReady={this.onGridReady} 
                    columnDefs={this.state.columnDefs} 
                    defaultColDef={this.state.defaultColDef} 
                    sortingOrder={this.state.sortingOrder} 
                    animateRows={true}
                    onDragStarted={this.onColumnDragEnter}
                    onRowDragEnd={this.onRowDragEnd}
                    onFirstDataRendered={this.onFirstDataRendered}
                    enableRangeSelection={true}
                    enableCharts={true}
                    debug={true}
                    customChartThemes={this.state.customChartThemes}
                    chartThemes={this.state.chartThemes}
                    chartThemeOverrides={this.state.chartThemeOverrides}> 
                </AgGridReact>

                <div id="myChart" className="ag-theme-alpine-dark my-chart"></div>
        </div>
            
        );
    }
    

}
