import ButtonCellRenderer from '../OrderCellRenderer';


import Moment from 'moment';

const columnDefs = [
        {
            rowDrag:false,
            cellRenderer: 'agGroupCellRenderer',
            field:"id", 
            
            minWidth: 150,
           // headerCheckboxSelection: true, 
            //headerCheckboxSelectionFilteredOnly: true, 
            //checkboxSelection:true, 
            filter:'agSetColumnFilter',  
            sortable:true,

            cellStyle:(params) => {
                return {fontWeight: 700}
            
            },


            getQuickFilterText: function(params) {
                return params.value;
            }
        },
        {


            minWidth: 150,
            field:"registered", 
            filter:true, 
            sortable:true,

            chartDataType: 'category',
            cellStyle: (params) => {
                if (params.value === "true") {
                    return {backgroundColor:'rgba(0,128,0, 0.4)'};   
                } else {
                    return {backgroundColor:'rgba(255,0,0,0.4)'}//'rgba(0,128,0,0.4)'};
                }
            },


            cellRenderer: (params) => {
                return params.value === 'true' ? 'Registered' : 'Not Registered';
            }
        },
        {

            minWidth: 150,
            field:"first_name", 
            filter:'agSetColumnFilter', 
            sortable:true
        },
        {

            minWidth: 150,
            field:"last_name",
            filter:'agSetColumnFilter',  
            sortable:true
        },
        {

            minWidth: 300,
            field:"email", 
            filter:'agSetColumnFilter', 
            sortable:true, 
        },
        {

            minWidth: 200,
            field:"date_created", 
            filter:'agSetColumnFilter', 
            sortable:true, 
            valueFormatter : (params) => {
                return `${new Moment(new Date(params.value)).format('DD/MM/YY h:mm a')}`;
            }
        },
       
    ];



export default columnDefs;