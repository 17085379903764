import ButtonCellRenderer from '../SubCellRenderer';


import Moment from 'moment';

const subDefs = [
        {
            rowDrag:false,
            cellRenderer: 'agGroupCellRenderer',
            field:"unique_id",
            headerName: 'Key',
            minWidth: 150,
           // headerCheckboxSelection: true, 
            //headerCheckboxSelectionFilteredOnly: true, 
            //checkboxSelection:true, 
            filter:'agSetColumnFilter',  
            sortable:true,

            cellStyle:(params) => {
                return {fontWeight: 700}
            
            },


            getQuickFilterText: function(params) {
                return params.value;
            }
        },
        {
            minWidth: 150,
            field:"status", 
            filter:true, 
            sortable:true,

            cellStyle:(params) => {
                switch (params.value) {
                    case 'onhold':
                        return {backgroundColor: 'rgba(200,200,0,0.5)'};
                    case 'cancelled':
                        return {backgroundColor: 'rgba(255,0,0,0.4)'};
                    case 'active':
                    default:
                    return {backgroundColor: 'rgba(0,128,0, 0.4)'};//'green'};
                }
            }
           
        },
        {
            minWidth: 150,
            field:"name", 
            filter:'agSetColumnFilter', 
            sortable:true
        
        },
        {
            minWidth: 150,
            field:"quantity", 
            filter:true, 
            sortable:true,

            cellStyle:(params) => {
                return {fontWeight: 700}
            
            },
        },

        {
            minWidth: 150,
            field:'total',
            headerName:'Amount',

           
          cellStyle : (params) => {
              return {textAlign:'right'}
          },
          valueFormatter: (params) => {
                return `$${params.value.toFixed(2)}`;
            }
            
        },
        
        {
            minWidth: 150,
            field:'total_payments',
            headerName:'Orders',
            sortable:true,

        },

        
        {
            minWidth: 300,
            field:"email", 
            filter:'agSetColumnFilter', 
            sortable:true, 
        },
        {
            minWidth: 150,
            field:"mobile", 
            filter:'agSetColumnFilter', 
            sortable:true, 
        },

        {   


            colId:'options',
            headerName: 'Options',
            minWidth: 400,
            editable: false,
            enableRowGroup: false,
            cellRenderer : ButtonCellRenderer,
            cellRendererParams: {
                
            },
        }
       /* {
            field:"bundle_price", 
            filter:'agSetColumnFilter', 
            sortable:true, 
            chartDataType: 'series',
            valueFormatter : (params) => {
                return `$${params.value.toFixed(2)}`
            }
        },

        
        {
            field:"date_created", 
            filter:'agSetColumnFilter', 
            sortable:true, 
            valueFormatter : (params) => {
                return `${new Moment(new Date(params.value)).format('DD/MM/YY h:mm a')}`;
            }
        },
       
        {   
            colId:'options',
            headerName: 'Options',
            minWidth: 400,
            editable: false,
            enableRowGroup: false,
            cellRenderer : ButtonCellRenderer,
            cellRendererParams: {
                
            } *////(e) => {
                //return '<button onclick="console.log(this)">Test</button>'
            //}
            
       // }
        ];



export default subDefs;