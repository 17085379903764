import React, {Component} from 'react';
import Moment from 'moment';


const getDetailTemplate = (params) => {
    let data = params.data;
    return (
        `<div class="detail-container">
                <div class="detail-column">
                    <p class="detail-column-header">
                    SHIPPING ADDRESS
                    </p>
                    <p class="detail-column-item">${data.address_line_1} ${data.address_line_2}</p>
                    <p class="detail-column-item">${data.city} ${data.state} ${data.postal_code}</p>
                    <p class="detail-column-item">${data.country}</p>
                </div>
                <div class="detail-column">
                    <p class="detail-column-header">
                    CONTACT INFORMATION
                    </p>
                    <p class="detail-column-item">${data.first_name} ${data.last_name}</p>
                    <p class="detail-column-item">${data.mobile}</p>
                    <p class="detail-column-item">${data.email}</p>
                </div>
                <div class="detail-column">
                    <p class="detail-column-header">
                    SUBSCRIPTION INFORMATION
                    </p>
                    <p class="detail-column-item">Next Payment: ${Moment(new Date(data.next_payment)).format('DD/MM/YYYY')}</p>
                    <p class="detail-column-item">Last Payment: ${Moment(new Date(data.last_payment)).format('DD/MM/YYYY')}</p>
                    <p class="detail-column-item">${data.quantity} ${data.quantity > 1 ? 'Boxes' : 'Box'}</p>
                    <p class="detail-column-item">Monthly</p>
                   
                </div>
                <div class="detail-column">
                <p class="detail-column-header">
                    PAYMENT INFORMATION
                </p>
                    <p class="detail-column-item"><span class="tag ${data.status}"><span class="tag-bullet"></span>${data.status}</span></p>
                    <p class="detail-column-item">${data.method_token}</p>
                    <p class="detail-column-item">$${data.total.toFixed(2)}</p>
                    <p class="detail-column-item">${data.payment_type} ${data.payment_type === `card` ? (`${data.card_type} Ending in ${data.lastfour} Expires on ${data.expiry}`) : (`${data.paypal_email}`)}</p>
                </div>

            </div>`

        /*
    `
        <div class="block">
        <div class="row left">
                
        </div>
        <div class="container toolbar">
            <div class="row full left" style={{paddingLeft: 5}}>
                <h2>${data.first_name} ${data.last_name}</h2>
                
            </div>
        
        </div>
        
        
        <div class="container toolbar">
                <div class="btn-row third top left">
                    <table>
                        <tbody>
                            <tr class="detail-row">
                                <td class="detail-li">
                                    <p>Email</p>
                                </td>
                                <td class="detail-li">
                                    <p>${data.email}</p>
                                </td>
                            </tr>
                            <tr class="detail-row">
                                <td class="detail-li">
                                    <p>Phone</p>
                                </td>
                                <td class="detail-li">
                                    <p>${data.mobile}</p>    
                                </td>
                            </tr>
                            <tr class="detail-row">
                                <td class="detail-li">
                                    <p>Status</p>
                                </td>
                                <td class="detail-li">
                                    <span class="tag ${data.status}"><span class="tag-bullet"></span>${data.status}</span>
                                </td>
                            </tr>
                            <tr class="detail-row">
                                <td class="detail-li">
                                    <p>Quantity</p>
                                </td>
                                <td class="detail-li">
                                    <p>${data.quantity}</p>
                                </td>
                            </tr>
                            <tr class="detail-row">
                                <td class="detail-li">
                                    <p>Amount</p>
                                </td>
                                <td class="detail-li">
                                    <p>$${data.total.toFixed(2)}</p>
                                </td>
                            </tr>
                            <tr class="detail-row">
                                <td class="detail-li">
                                    <p>Payment Token</p>
                                </td>
                                <td class="detail-li">
                                    <p>${data.method_token}</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="btn-row third top">
                    <table>
                        <tbody>
                            <tr class="detail-row">
                                <td class="detail-li">
                                    <p>Payment Type</p>
                                </td>
                                <td class="detail-li">
                                    <p>${data.payment_type}</p>
                                </td>
                            </tr>
                            <tr class="detail-row">
                                <td class="detail-li">
                                    <p>${data.payment_type === 'card' ? 'Card Type' : 'Paypal Email'}</p>
                                </td>
                                <td class="detail-li">
                                    <p>${data.payment_type ==='card' ? data.card_type : data.paypal_email}</p>    
                                </td>
                            </tr>

                            ${data.payment_type === 'card' ? ` 
                                <tr class="detail-row">
                                    <td class="detail-li">
                                        <p>Last Four Digits</p>
                                    </td>
                                    <td class="detail-li">
                                        <p>${data.lastfour}</p>   
                                    </td>
                                </tr>
                                <tr class="detail-row">
                                    <td class="detail-li">
                                        <p>Expiry</p>
                                    </td>
                                    <td class="detail-li">
                                        <p>${data.expiry}</p>   
                                    </td>
                                </tr>
                            `
                        
                        : ``}
                           
                            <tr class="detail-row">
                                <td class="detail-li">
                                    <p>Next Payment</p>
                                </td>
                                <td class="detail-li">
                                    <p>${Moment(new Date(data.next_payment)).format('DD/MM/YYYY')}</p>
                                </td>
                            </tr>
                            <tr class="detail-row">
                                <td class="detail-li">
                                    <p>Last Payment</p>
                                </td>
                                <td class="detail-li">
                                    <p>${Moment(new Date(data.last_payment)).format('DD/MM/YYYY')}</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="btn-row third top right">
                    <table>
                        <tbody>
                            <tr class="detail-row">
                                <td class="detail-li">
                                    <p>Address</p>
                                </td>
                                <td class="detail-li">
                                    <p>${data.address_line_1} ${data.address_line_2}</p>
                                </td>
                            </tr>
                            <tr class="detail-row">
                                <td class="detail-li">
                                    <p>City</p>
                                </td>
                                <td class="detail-li">
                                    <p>${data.city}</p>
                                </td>
                            </tr>
                            <tr class="detail-row">
                                <td class="detail-li">
                                    <p>State</p>
                                </td>
                                <td class="detail-li">
                                    <p>${data.state}</p>
                                </td>
                            </tr>
                            <tr class="detail-row">
                                <td class="detail-li">
                                    <p>Postcode</p>
                                </td>
                                <td class="detail-li">
                                    <p>${data.postal_code}</p>
                                </td>
                            </tr>
                            <tr class="detail-row">
                                <td class="detail-li">
                                    <p>Country</p>
                                </td>
                                <td class="detail-li">
                                    <p>${data.country}</p>
                                </td>
                            </tr>
                            <tr class="detail-row">
                                <td class="detail-li">
                                    <p>Date</p>
                                </td>
                                <td class="detail-li">
                                    <p>${new Moment(new Date(data.date_created)).format('DD/MM/YY H:MM a')}</p>
                                </td>
                            </tr>
                            

                        </tbody>
                    </table>
                </div>
                
        </div>
    </div>`*/

    );
};



export default getDetailTemplate;