import React from 'react';
import {sendPost, getData} from '../../../../Ajax';


// cell renderer class
export default class ButtonCellRenderer {}



ButtonCellRenderer.prototype.draw = function(params) {
    console.log(params);
 } 



 ButtonCellRenderer.prototype.refresh  = function (params) {
     console.log("REFRESH CALLED")
    return true;
  };



// init method gets the details of the cell to be rendere
ButtonCellRenderer.prototype.init = function(params) {
    this.eGui = document.createElement('span');



    /**
     * Creates the order fulfillment button.
     */
    this.statusButton = document.createElement('button');
    this.statusButton.innerHTML = params.node.data.status === 'cancelled' ? 'Reinstate' : (params.node.data.status === 'onhold' ? 'Resume' : 'Pause');



    /**
     * Fulfillment event listener
     */
    this.statusButton.addEventListener('click', () => {
        let api = params.api;
        let node = params.node;
        let data = node.data;
        
        data.status = data.status === 'cancelled' ? 'onhold' : (data.status === 'active' ? 'onhold' : 'active');
        
        if (data.status !== 'cancelled') {
            this.cancelButton.innerHTML = 'Cancel';
            this.cancelButton.disabled = false;
        }

        this.statusButton.innerHTML = data.status === 'cancelled' ? 'Reinstate' : (data.status === 'active' ? 'Pause' : 'Resume');
        params.api.applyTransaction({update: [node.data]});
        sendPost('/subscriptions/update', {id:data.unique_id, target:'status', update:data.status}).then(result => console.log(result));
    });





    /**
     * Creates the cancel button.
     */
    this.cancelButton = document.createElement('button');
    this.cancelButton.innerHTML = params.node.data.status ==='cancelled' ? 'Cancelled' : 'Cancel';
    this.cancelButton.disabled = params.node.data.status === 'cancelled' ? true : false;
    
    
    
    /**
     * Cancellation listener
     */
    this.cancelButton.addEventListener('click', () => {
        let api = params.api;
        let node = params.node;
        let data = node.data;
        data.status = 'cancelled';
        this.statusButton.innerHTML = 'Reinstate';
        node.setData(data);
        this.cancelButton.disabled = true;
        this.cancelButton.innerHTML = data.status === 'cancelled' ? 'Cancelled' : 'Cancel';
        
        params.api.applyTransaction({update: [node.data]});
        sendPost('/subscriptions/update', {id:data.unique_id, target:'status', update:data.status}).then(result => console.log(result));
    });






    this.eGui.appendChild(this.statusButton);
    this.eGui.appendChild(this.cancelButton);

};

ButtonCellRenderer.prototype.getGui = function() {
    return this.eGui;
};
