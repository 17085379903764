import React, {Component} from 'react';
import {AgGridColumn} from 'ag-grid-react';


import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { AgGridReact } from '@ag-grid-community/react';

import {AllModules} from '@ag-grid-enterprise/all-modules';
import subDefs from './defaults/definitions/subheaders';


import getDetailTemplate from './detail/SubDetail';
import { getData } from '../../../Ajax';

export default class SubsGrid extends Component {



    state = {

        loading : false,
        colId:'unique_id',
        sortingOrder: ['desc', 'asc', null],
        columnDefs: subDefs,
        defaultColDef: {
          resizable: true, 
          flex: 1, 
          autoHeight: true,
          enableRowGroup: true,
          enablePivot: true,
          enableValue: true,
        },

        rowGroupPanelShow: 'never',//'always',//
        
        rowData: null,
        lastExpanded: '1',
        gridApi: null,
        gridColumnApi: null,
        modules: AllModules,
        


        detailCellRendererParams: {
          
          refreshStrategy: 'everything',

          template: function(params) {
            return getDetailTemplate(params);
          },

          detailGridOptions: {
            immutableData: true,
    
          },

          getDetailRowData: function(params) {
            console.log('GETTING ROW DATA');
            params.successCallback(params.data);
        },
      
        },
        


        gridOptions: {
           
            pagination: false,
            onRowClicked : (event) => {
                console.log('ROW CLICKED', event);
            },
            onColumnResized: function(event) { console.log('A column was resized'); },
            onGridReady: function(event) { console.log('The grid is now ready'); },
            // CALLBACKS
            //isScrollLag: function() { return false; },



          getContextMenuItems: (params) => {
            console.log(params);
           if (params.column === null) {
             return ['copy'];
           } else {
             return [
               'autoSizeAll',
               'copy',
               'copyWithHeaders',
               'paste',
               'resetColumns',
               'export',
               'csvExport',
               'excelExport',
               'excelXmlExport',
               'chartRange',
             ]
           }
         },

            
        },

        groupDefaultExpanded: 1,

        detailRowHeight: 200,
    }


    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.setState({gridApi: this.gridApi, gridColumnApi: this.gridColumnApi});
        
        getData('/subscriptions/get').then(data => {
            this.setState({rowData:data.data});
        });
    }


    refreshGrid = e => {
        console.log('View refreshed.')
        this.state.gridOptions.api.refreshCells();
    }

    onFilterTextBoxChanged = (e) => {
        console.log(e);
        this.gridApi.setQuickFilter(e.target.value);
  
      }
  

    // Render.
    render() {
        return(
            <div className="ag-theme-alpine" style={{ height: 900, width: '100%'}}>
           
                <div className="container toolbar" style={{paddingLeft: 5}}>
                    <div className="btn-row full left">
                        
                      <div className="input-group">
                        <label>Search for:</label>
                        <input type="text" placeholder="Type here to filter..." onInput={this.onFilterTextBoxChanged}/>
                      </div>
                    </div>
                </div>
                    
                <AgGridReact
                    modules={this.state.modules}
                    enableMultiRowDragging={true}
                    rowDragManaged={false}
                    rowHeight="30"
                    gridOptions={this.state.gridOptions}
                    rowGroupPanelShow={this.state.rowGroupPanelShow}
                    masterDetail={true}
                    enableCellChangeFlash={true}
                    detailCellRendererParams={this.state.detailCellRendererParams}
                    detailRowHeight={this.state.detailRowHeight}
                    rowData={this.state.rowData} 
                    onGridReady={this.onGridReady} 
                    columnDefs={this.state.columnDefs} 
                    defaultColDef={this.state.defaultColDef} 
                    sortingOrder={this.state.sortingOrder} 
                    animateRows={true}
                    onDragStarted={this.onColumnDragEnter}
                    onRowDragEnd={this.onRowDragEnd}
                    onFirstDataRendered={this.onFirstDataRendered}
                    enableRangeSelection={true}
                    debug={true}/>

        </div>
            
        );
    }
}