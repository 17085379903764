import React, {Component} from 'react';


import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";


import {getData} from '../Ajax';

export default class Header extends Component {
    state = {

        renewals: 0
    };




    componentDidMount() {
        getData('/subscriptions/renewals/count').then(data => this.setState({renewals: data.count}));
    }

    componentDidUpdate() {

    }




    render() {
        

        
        return (
        <header>
            <div className="container center">
                 <img alt="logo" width={400} height={200} src="https://scatbags.com/assets/logo/scatbagslogo.svg"/>
            </div>
            <div className='btn-row title-bar'>
                <Router forceRefresh={true}>
                    <Link to="/orders">
                        <button className={`${(this.props.page === '/orders' ? 'selected' :'')} standard-btn`}>
                            Orders
                        </button>
                    </Link>
                    <Link to="/subscriptions">
                        <button className={`${(this.props.page === '/subscriptions' ? 'selected' :'')} standard-btn`}>
                            Subscriptions
                        </button>
                    </Link>
                    <Link to="/renewals">
                        <button className={`${(this.props.page === '/renewals' ? 'selected' : '')} notify standard-btn`}>
        Renewals {this.state.renewals ? <span className="btn-notify">{this.state.renewals}</span> : ''}
                        </button>
                    </Link>
                    <Link to="/customers">
                        <button className={`${this.props.page === '/customers' ? 'selected' :''} standard-btn`}>
                           Customers
                        </button>
                    </Link>
                </Router>
                <button onClick={() => {this.props.logout()}} className={`${this.props.page === '/logout' ? 'selected' :''} standard-btn`}>
                            Logout
                </button>
            </div>
        </header>)
    }
}