import React, {Component} from 'react';
import {AgGridColumn} from 'ag-grid-react';


import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { AgGridReact } from '@ag-grid-community/react';

import {AllModules} from '@ag-grid-enterprise/all-modules';
import renewDefs from './defaults/definitions/renewalheaders';


import getDetailTemplate from './detail/SubDetail';
import { getData, sendPost } from '../../../Ajax';

import {appendLoader, appendCheckmark, appendButtonCheckmark, appendError} from '../../../ElementBuilder';

export default class RenewalGrid extends Component {



    state = {

        loading : false,
        colId:'unique_id',
        sortingOrder: ['desc', 'asc', null],
        columnDefs: renewDefs,
        defaultColDef: {
          resizable: true, 
          flex: 1, 
          autoHeight: true,
          enableRowGroup: true,
          enablePivot: true,
          enableValue: true,
        },

        rowGroupPanelShow: 'never',//'always',//
        
        rowData: null,
        lastExpanded: '1',
        gridApi: null,
        gridColumnApi: null,
        modules: AllModules,


        detailCellRendererParams: {
          
          refreshStrategy: 'everything',

          template: function(params) {
            return getDetailTemplate(params);
          },

          detailGridOptions: {
            immutableData: true,
    
          },

          getDetailRowData: function(params) {
            console.log('GETTING ROW DATA');
            params.successCallback(params.data);
        },
      
        },
        


        gridOptions: {
           
            pagination: false,
            onRowClicked : (event) => {
                console.log('ROW CLICKED', event);
            },
            onColumnResized: function(event) { console.log('A column was resized'); },
            onGridReady: function(event) { console.log('The grid is now ready'); },
            // CALLBACKS
            //isScrollLag: function() { return false; },

            headerHeight: 50,
            getContextMenuItems: (params) => {
                console.log(params);
               if (params.column === null) {
                 return ['copy'];
               } else {
                 return [
                   'autoSizeAll',
                   'copy',
                   'copyWithHeaders',
                   'paste',
                   'resetColumns',
                   'export',
                   'csvExport',
                   'excelExport',
                   'excelXmlExport',
                   'chartRange',
                 ]
               }
             },
        },

        groupDefaultExpanded: 1,
        detailRowHeight: 200,
    }


    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.setState({gridApi: this.gridApi, gridColumnApi: this.gridColumnApi});
        
        getData('/subscriptions/renewals/get').then(data => {
            this.setState({rowData:data.data});
        });
    }


    refreshGrid = e => {
        console.log('View refreshed.');
        this.state.gridOptions.api.refreshCells();
    }

    onFilterTextBoxChanged = (e) => {
        console.log(e);
        this.gridApi.setQuickFilter(e.target.value);
  
      }


    renewAll = async (e) => {
        if (this.state.loading) {
            return;
        }
        let renewbtns = document.querySelectorAll('button.renew-btn:not(.renewed):not(.renewall)');
        

        if (renewbtns.length > 0) {
            let renewing = true;
            this.setState({loading: true});

            e.target.innerHTML = '';
            appendLoader(e.target);
            e.target.disabled = true;

            renewbtns.forEach(btn => {
                btn.innerHTML = '';
                btn.disabled = true;
                appendLoader(btn, 'tiny');
            });

         //   let count = 0;
           // setInterval(async () => {
            for (let count = 0; count < renewbtns.length; count++) {
                let btn = renewbtns[count];
                let id = btn.id.replace('renew-btn-', '');

                await sendPost('/subscriptions/renew', {id}).then(result => {
                    if (result.success) {
              
                        btn.innerHTML = '';
                        appendButtonCheckmark(btn, 'tiny');
                        btn.classList.add('renewed');
                        btn.disabled = false;
                
                    } else {
                        btn.innerHTML = 'Retry';
                        btn.classList.add('error');
                        btn.disabled = false;
                        
                    }

                });

               // count++;

                


            }//, 600);


            this.setState({loading: false});
            e.target.innerHTML = 'Renew All';
            e.target.disabled = false;
        }
        
        
        


    }
  

    // Render.
    render() {
        return(
            <div className="ag-theme-alpine" style={{ height: 900, width: '100%'}}>
           
                <div className="container toolbar" style={{paddingLeft: 5}}>
                    <div className="btn-row full left">
                        
                      <div className="input-group">
                        <label>Search for:</label>
                        <input type="text" placeholder="Type here to filter..." onInput={this.onFilterTextBoxChanged}/>
                        <button className="renew-btn renewall standard-btn" onClick={this.renewAll}>Renew All</button>
                      </div>
                    </div>
                </div>
                    
                <AgGridReact
                    modules={this.state.modules}
                    enableMultiRowDragging={true}
                    rowDragManaged={false}
                    rowHeight="30"
                    gridOptions={this.state.gridOptions}
                    rowGroupPanelShow={this.state.rowGroupPanelShow}
                    masterDetail={true}
                    enableCellChangeFlash={true}
                    detailCellRendererParams={this.state.detailCellRendererParams}
                    detailRowHeight={this.state.detailRowHeight}
                    rowData={this.state.rowData} 
                    onGridReady={this.onGridReady} 
                    columnDefs={this.state.columnDefs} 
                    defaultColDef={this.state.defaultColDef} 
                    sortingOrder={this.state.sortingOrder} 
                    animateRows={true}
                    onDragStarted={this.onColumnDragEnter}
                    onRowDragEnd={this.onRowDragEnd}
                    onFirstDataRendered={this.onFirstDataRendered}
                    enableRangeSelection={true}
                    debug={true}/>

        </div>
            
        );
    }
}